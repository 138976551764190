import { render, staticRenderFns } from "./courseOrderSelector.vue?vue&type=template&id=635edebd&scoped=true&"
import script from "./courseOrderSelector.vue?vue&type=script&lang=js&"
export * from "./courseOrderSelector.vue?vue&type=script&lang=js&"
import style0 from "./courseOrderSelector.vue?vue&type=style&index=0&id=635edebd&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635edebd",
  null
  
)

export default component.exports