<template>
  <div class="plan-add">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        续课审核
      </div>
      <div class="right"></div>
    </div>
    <div class="title-block">
      <div class="title">
        <img :src="homeWorkIcon" />
        <input type="text" class="title-input" v-model="req.title" placeholder="标题" />
      </div>
    </div>
    <div class="main-block">
      <div class="main">
        <div class="course-block" @click="openOrderSelector">
          <img class="select-course-icon" :src="selectCourseIcon" />
          <div class="select-course-title">
            {{ selectUserOwnCourse ? selectUserOwnCourse.courseName : "" }}
          </div>
          <img class="select-course-expand-icon" :src="downArrowIcon" />
        </div>
        <div class="text-block">
          <textarea class="plan-text" v-model="req.workText"> </textarea>
        </div>
        <div class="upload-block">
          <!-- <upload v-model="req.userAttach" :readonly="false" :bizType="bizType"> </upload> -->
          <aidaUpload
            v-model="req.userAttach"
            :readonly="false"
            :bizType="bizType"
            :isSingleFile="false"
            :maxSize="100"
            v-on:update-attach="updateAttach"
          >
          </aidaUpload>
        </div>
        <div class="split-line" v-if="req.teacherAttach && req.teacherAttach.length > 0"></div>
        <div class="upload-block" v-if="req.teacherAttach && req.teacherAttach.length > 0">
          <!-- <upload v-model="req.teacherAttach" :readonly="true"> </upload> -->
          <aidaUpload
            v-model="req.teacherAttach"
            :readonly="true"
            :bizType="bizType"
            :isSingleFile="false"
            :maxSize="100"
            v-on:update-attach="updateAttach"
          ></aidaUpload>
        </div>
      </div>
    </div>
    <div class="btn-block">
      <div class="btn-tool">
        <button class="btn" @click="onSave" v-if="canSave">
          保存
        </button>
        <button class="btn-disable" v-else>
          保存中..
        </button>
      </div>
    </div>
    <div class="audit-block">
      <div class="text-info">
        <div class="info">请上传本人考试成绩。</div>
      </div>
    </div>
    <!-- //传入的是用户拥有的课程 -->
    <userOwnCourseSelector
      v-if="showUserOwnCourseSelector"
      :userOwnCourseList="userOwnCourseList"
      v-model="selectUserOwnCourse"
    ></userOwnCourseSelector>
  </div>
</template>

<script>
import { courseExtendApi } from "@/api/courseExtendApi.js";
import { Toast, Notify } from "vant";

import aidaUpload from "@/components/aidaUpload.vue";
import userOwnCourseSelector from "@/components/userOwnCourseSelector.vue";
import courseOrderSelector from "@/components/courseOrderSelector.vue";
import dayjs from "dayjs";
import { matchFileType } from "@/util/validate";
import { cosApi } from "@/api/cosApi.js";
export default {
  components: {
    userOwnCourseSelector,
    aidaUpload,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      homeWorkIcon: require("@/assets/images/user-home-work/home-work.png"),
      selectCourseIcon: require("@/assets/images/user-home-work/select-course.png"),
      downArrowIcon: require("@/assets/images/user-home-work/down-arrow.png"),
      timeIcon: require("@/assets/images/user-learn-plan/date.png"),
      bizType: "user_course_extend_review",

      id: 0,

      req: {
        id: 0,
        title: "",
        workText: "",
        hasReview: false,
        userAttach: [],
        teacherText: "",
        teacherAttach: [],
      },

      showUserOwnCourseSelector: false,
      userOwnCourseList: [],
      selectUserOwnCourse: null,

      canSave: true,
      paramCourseId: 0,
    };
  },
  methods: {
    init() {
      courseExtendApi.userOwnCourse().then((ret) => {
        console.log("###", ret);
        let { code, datas } = ret;
        if (code == 0 && datas) {
          this.userOwnCourseList = datas;
        }
      });
    },
    async initDbData(id) {
      let [ret, err] = await courseExtendApi.awaitChain(courseExtendApi.get(id));
      // console.log("#", ret);
      if (ret && ret.code == 0 && ret.data) {
        this.wrapAttach(ret.data.userAttach);
        this.wrapAttach(ret.data.teacherAttach);
        this.req = ret.data;
      } else {
        Notify({
          type: "danger",
          message: "系统出错，请联系客服",
        });
      }
      [ret, err] = await courseExtendApi.awaitChain(courseExtendApi.userOwnOrder());
      // console.log("##", ret);
      if (ret && ret.code == 0) {
        for (let item of ret.datas) {
          if (item.orderId == this.req.orderId) {
            this.selectUserOwnCourse = item;
          }
        }
        this.ownOrderList = ret.datas;
      }
    },
    onGoBack() {
      //this.$router.go(-1);
      this.$router.push({
        path: "/courseExtend",
      });
    },
    openOrderSelector() {
      // console.log("openCourseSelector");
      this.showUserOwnCourseSelector = true;
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        if (item.fileType) {
          continue;
        }
        item.fileType = matchFileType(item.key);
      }
    },
    // 保存
    onSave() {
      if (!this.selectUserOwnCourse || !this.selectUserOwnCourse.orderId) {
        Notify({
          type: "warning",
          message: "必须先选择课程资产",
        });
        return;
      }
      this.req.orderId = this.selectUserOwnCourse.orderId;
      this.req.oldCourseId = this.selectUserOwnCourse.courseId;
      if (!this.req.title) {
        Notify({
          type: "warning",
          message: "标题不能为空",
        });
        return;
      }
      if (!this.req.workText) {
        Notify({
          type: "warning",
          message: "请输入申请理由",
        });
        return;
      }
      if (!this.req.oldCourseId) {
        Notify({
          type: "warning",
          message: "必须选择课程",
        });
        return;
      }

      this.canSave = false;

      this.req.hasReview = false; // 每次提交都设置成没批改的状态
      this.req.startTime = dayjs().valueOf();
      this.req.endTime = this.req.startTime;
      console.log("onSave", this.req);
      courseExtendApi.addOrUpdate(this.req).then((res) => {
        if (res.code == 0) {
          cosApi.batchAddCos(this.req.userAttach).then((ret) => {
            if (ret.code == 0 && ret.data) {
              Notify({ type: "success", message: "新增成功" });
              this.onGoBack();
              this.canSave = true;
              return;
            }
          });
        }
      });
    },

    listenOnCloseOrderSelector() {
      this.$EventBus.$on("onCloseUserOwnCourseSelector", (val) => {
        this.showUserOwnCourseSelector = false;
      });
    },
    updateAttach(retList) {
      console.log("update attach", retList);
    },
  },
  mounted() {
    this.listenOnCloseOrderSelector();
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.initDbData(this.id);
    } else {
      this.init();
    }
  },
  destroyed() {},
  filters: {},
  watch: {
    attach(newValue) {
      //console.log(newValue);
    },
    selectUserOwnCourse() {
      console.log("selectUserOwnCourse", this.selectUserOwnCourse);
      this.req.orderId = this.selectUserOwnCourse.orderId;
      this.req.oldCourseId = this.selectUserOwnCourse.courseId;
    },
    // endTimeStr() {
    //   this.getEpoch();
    // },
    // startTimeStr() {
    //   this.getEpoch();
    // },
    // startDateStr() {
    //   this.getEpoch();
    // },
    // endDateStr() {
    //   this.getEpoch();
    // },
  },
};
</script>

<style lang="less" scoped>
.plan-add {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      width: 344px;
      height: 44px;
      background: #ffffff;
      border-radius: 8px;
      margin: 14px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      img {
        height: 16px;
        width: 16px;
        margin: 14px 12px 10px 12px;
      }
      .title-input {
        width: 300px;
        font-size: 16px;
        color: #c3c3c3;
        letter-spacing: 0.36px;
        border: none;
      }
    }
  }
  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .main {
      background: #ffffff;
      border-radius: 8px;
      width: 344px;
      margin: 6px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      .course-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 42px;
        margin: 0 12px;
        border-bottom: 1px solid rgb(242, 242, 242);
        .select-course-title {
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          width: 270px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .select-course-icon {
          height: 16px;
          width: 16px;
        }
        .select-course-expand-icon {
          height: 8px;
          width: 14px;
        }
      }
      .datetime-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 42px;
        font-size: 16px;
        margin: 0 12px;
        color: #505051;
        letter-spacing: 0.36px;
        border-bottom: 1px solid rgb(242, 242, 242);
        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          img {
            height: 16px;
            width: 16px;
            margin: 12px 12px 12px 0px;
          }
        }
        .right {
          display: flex;
          flex-direction: row;
          align-items: center;
          .calendar-val {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 96px;
            background: #efefef;
            border-radius: 8px;
            font-size: 14px;
            color: #323233;
            letter-spacing: 0.31px;
            margin: 0px 4px 0px 0px;
          }
          .time-val {
            width: 50px;
          }
        }
      }
      .text-block {
        margin: 14px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan-text {
          height: 132px;
          width: 318px;
          font-size: 14px;
          color: #c3c3c3;
          letter-spacing: 0.31px;
          border: none;
          resize: none;
        }
      }
      .upload-block {
        margin: 12px;
      }
      .split-line {
        margin: 0px 12px;
        height: 1px;
        border-bottom: 1px solid rgb(242, 242, 242);
      }
    }
  }
  .btn-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-tool {
      margin: 30px 0px 0px 0px;
      display: flex;
      flex-direction: row-reverse;
      width: 344px;
      .btn {
        width: 110px;
        height: 40px;
        background: #4d52d1;
        border: none;
        border-radius: 14px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
      }
      .btn-disable {
        background: gray;
        width: 110px;
        height: 40px;
        border: none;
        border-radius: 14px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
      }
    }
  }
  .audit-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: #f3f5f7;
    .text-info {
      width: 344px;
      margin: 40px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .info {
        font-size: 11px;
        color: #9298a4;
        letter-spacing: 0.24px;
        margin: 0px 0px 18px 0px;
      }
    }
  }
}
</style>
