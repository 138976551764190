//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const addOrUpdate = (data) => {
  return ajax.post(`${base}/api/itedu/v1/user-course-extend-review/add-edit`, data);
};

const cosPrefix = () => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/cos-prefix`);
};

const get = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/get?id=${id}`);
};

const query = (hasReview, startTime, endTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-course-extend-review/query?hasReview=${hasReview}&fromTime=${startTime}&toTime=${endTime}`
  );
};

const cdnUrl = (cosKey) => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/cdn-url?cosKey=${cosKey}`);
};

const userOwnOrder = () => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/course-order-selector`);
};

const userOwnCourse = () => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/user-own-course-selector`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const courseExtendApi = {
  // import时得花括号明确
  addOrUpdate: addOrUpdate,
  cosPrefix: cosPrefix,
  get: get,
  query: query,
  cdnUrl: cdnUrl,
  userOwnOrder: userOwnOrder,
  userOwnCourse: userOwnCourse,
  awaitChain: awaitChain,
};
